import PropTypes from "prop-types"
import React from "react"
const Header = ({ siteTitle }) => (

  <div
    style={{
      margin: "6px",
      maxWidth: 960,
      padding: `.875rem`,
    }}
  >
    <h1 style={{
      margin: 0,
      fontWeight: 500,
      color: `white`
    }}>
      Justin Hammett
      </h1>
    <p
      style={{
        fontSize: "1.4rem",
        fontWeight: "lighter",
        fontFamily: "Roboto",
        color: `white`
      }}>
      UX Minded Full Stack Developer</p>
  </div>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header



