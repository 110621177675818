/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import styled from "styled-components"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <StyledDiv
        style={{
          margin: `0px 20px`,
          maxWidth: 960,

          // padding: `0 1.0875rem 1.45rem`,
                 }}
      >
        <main>{children}</main>
      
      </StyledDiv>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const StyledDiv = styled.div`

main {
  display: flex;
  justify-content: space-between;


}
@media(max-width:  1470px) {
  main {
    display: flex;
  flex-direction: column;
  
  }

}
@media(max-width: 990px) {
  main {
    display: flex;
  flex-direction: column;
  
  }

}


`